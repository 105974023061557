<template lang="pug">
  div
    h5.group-text(v-if="time") {{ getTime }}
    h5.group-text(v-if="available") {{ item.total_amount }}
    h5.group-text(v-if="latest") +{{ item.difference }}
</template>

<script>
import { convertToDefaultHourFormat } from '@/util';

export default {
  name: 'CbrUpdatesChildItem',

  props: {
    item: Object,
    time: { type: Boolean, default: false },
    available: { type: Boolean, default: false },
    latest: { type: Boolean, default: false }
  },

  computed: {
    getTime() {
      return convertToDefaultHourFormat(this.item.datetime)
    }
  }
}
</script>